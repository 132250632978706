import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://dex.swapdegen.tips/#/swap',
      },
      {
        label: 'Liquidity',
        href: 'https://dex.swapdegen.tips/#/pool/v2',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/treatbowl',
  },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  // {
  //   label: 'Lucky Dog Lotto',
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },
  // {
  //   label: 'NFT',
  //   icon: 'NftIcon',
  //   href: '/nft',
  // },
  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'CoinGecko',
        href: '',
      },
      // {
      //   label: 'CHEWY Chart',
      //   href: 'https://dexscreener.com/shibarium/0x324eef33af720ce44deab7e32f4367a82b4ea43b',
      // },
      {
        label: 'CHEWY Chart',
        href: 'https://dexscreener.com/degenchain',
      },
      {
        label: 'ChewySwap Pools',
        href: 'https://dexscreener.com/degenchain',
      },
    ],
  },
  {
    label: 'Socials',
    icon: 'MoreIcon',
    items: [
      {
        label: "Telegram",
        href: "https://t.me/chewyswapdegen",
      },
      {
        label: "X",
        href: "https://twitter.com/Chewyswapdegen",
      },
    ],
  },
//  {
//    label: 'Partnerships',
//    icon: 'GooseIcon',
//    href: 'https://docs.google.com/forms/d/e/1FAIpQLSe7ycrw8Dq4C5Vjc9WNlRtTxEhFDB1Ny6jlAByZ2Y6qBo7SKg/viewform?usp=sf_link',
//  },
// {
//   label: 'Audits',
//   icon: 'AuditIcon',
//   items: [
//     {
//       label: 'Shibarium Audit',
//       href: 'https://contractwolf.io/projects/chewyswap',
//     },
//     {
//       label: 'Dogechain Audit',
//       href: 'https://contractwolf.io/projects/dogeshrek',
//     },
//   ]
// },
]

export default config
