export default {
  cake: {
    666666666: '0x67d6BF178464F321A281EBFF551f34d2408bF672',
    56: '0xF952Fc3ca7325Cc27D15885d37117676d25BfdA6',
    97: '',
  },
  masterChef: {
    666666666: '0x9c077B97853A1c49a6F47E5B80211FCb14d98886',
    56: '0xe70E9185F5ea7Ba3C5d63705784D8563017f2E57',
    97: '',
  },
  wbnb: {
    666666666: '0xEb54dACB4C2ccb64F8074eceEa33b5eBb38E5387',
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
  },
  lottery: {
    666666666: '',
    56: '',
    97: '',
  },
  lotteryNFT: {
    666666666: '',
    56: '',
    97: '',
  },
  mulltiCall: {
    666666666: '0x0698eB13Ad662D203f934e39970AB905812Cad1f',
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    666666666: '0xaB082b8ad96c7f47ED70ED971Ce2116469954cFB',
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
}
